<template>
  <el-container class="shopList">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>人员管理</el-breadcrumb-item>
        <el-breadcrumb-item v-if="user_type == 1" to="/admin/sample"
          >监管人员列表</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="form.user_id && user_type == 1"
          >编辑监管人员</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="!form.user_id && user_type == 1"
          >新增监管人员</el-breadcrumb-item
        >
      </el-breadcrumb>
      <div>
        <el-button class="back_btn" size="medium" plain @click="$router.back()"
          >返回</el-button
        >
      </div>
    </el-header>
    <el-main class="p15 pt15">
      <el-form
        :inline="true"
        status-icon
        ref="form"
        :model="form"
        class="demo-ruleForm"
      >
        <el-row class="box mt20 f14">
          <el-col :span="7" class="tl clear">
            <el-form-item
              size="medium"
              label="身份证(头像面)："
              class="w"
              label-width="41%"
              prop="accessory_list[0].accessory_url"
              :rules="[
                {
                  required: true,
                  message: '请上传身份证头像面',
                  trigger: 'blur',
                },
              ]"
            >
              <div class="recordadd-upload">
                <el-upload
                  ref="upload"
                  multiple
                  v-if="imageUrl == '1234567'"
                  type="file"
                  :file-list="fileList"
                  action="/pc/file/upload"
                  list-type="picture-card"
                  :before-upload="beforeAvatarUpload"
                  accept="image/png,image/jpg,image/jpeg"
                  :on-success="handlepoll"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :class="{ disabled: uploadDisabled }"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-upload
                  ref="upload"
                  multiple
                  type="file"
                  :file-list="fileList"
                  action=""
                  v-else
                  list-type="picture-card"
                  :before-upload="beforeAvatarUpload"
                  accept="image/png,image/jpg,image/jpeg"
                  :http-request="
                    (params) => {
                      return handlepoll(params);
                    }
                  "
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :class="{ disabled: uploadDisabled }"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
                <div class="fl">已开启OCR自动识别</div>
              </div>
              <!-- <div v-else class="recordadd-upload">
                <el-image v-if="form.accessory_list[0].accessory_url"  style="width: 160px;height: 160px"
                  :src="form.accessory_list[0].accessory_url"
                  :preview-src-list="[form.accessory_list[0].accessory_url]" alt=""></el-image>
                <img v-else src="../../../assets/image/moren.png" style="width: 160px;height: 160px" />
              </div> -->
            </el-form-item>
          </el-col>
          <el-col :span="7" class="tl">
            <el-form-item
              size="medium"
              label="身份证(国徽面)："
              class="w"
              label-width="41%"
              prop="accessory_list[1].accessory_url"
              :rules="[
                {
                  required: true,
                  message: '请上传身份证国徽面',
                  trigger: 'blur',
                },
              ]"
            >
              <div class="recordadd-upload">
                <el-upload
                  ref="upload"
                  multiple
                  v-if="imageUrl == '1234567'"
                  action="/pc/file/upload"
                  list-type="picture-card"
                  :file-list="fileList1"
                  :before-upload="beforeAvatarUpload"
                  accept="image/png,image/jpg,image/jpeg"
                  :on-success="handlepoll1"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove1"
                  :before-remove="beforeRemove"
                  :class="{ disabled: uploadDisabled1 }"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-upload
                  ref="upload"
                  multiple
                  action=""
                  v-else
                  list-type="picture-card"
                  :file-list="fileList1"
                  :before-upload="beforeAvatarUpload"
                  accept="image/png,image/jpg,image/jpeg"
                  :http-request="
                    (params) => {
                      return handlepoll1(params);
                    }
                  "
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove1"
                  :before-remove="beforeRemove"
                  :class="{ disabled: uploadDisabled1 }"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
                <div class="fl">已开启OCR自动识别</div>
              </div>
              <!-- <div v-else class="recordadd-upload">
                <el-image v-if="form.accessory_list[1].accessory_url" style="width: 160px;height: 160px"
                  :src="form.accessory_list[1].accessory_url"
                  :preview-src-list="[form.accessory_list[1].accessory_url]" alt=""></el-image>
                <img v-else src="../../../assets/image/moren.png" style="width: 160px;height: 160px" />
              </div> -->
            </el-form-item>
          </el-col>
          <el-col :span="7" class="tl">
            <el-form-item
              size="medium"
              label="健康证："
              class="w"
              label-width="41%"
            >
              <div class="recordadd-upload">
                <el-upload
                  ref="upload"
                  multiple
                  v-if="imageUrl == '1234567'"
                  action="/pc/file/upload"
                  list-type="picture-card"
                  :file-list="fileList2"
                  :before-upload="beforeAvatarUpload"
                  accept="image/png,image/jpg,image/jpeg"
                  :on-success="handlepoll2"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove2"
                  :before-remove="beforeRemove"
                  :class="{ disabled: uploadDisabled2 }"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-upload
                  ref="upload"
                  multiple
                  action=""
                  v-else
                  list-type="picture-card"
                  :file-list="fileList2"
                  :before-upload="beforeAvatarUpload"
                  accept="image/png,image/jpg,image/jpeg"
                  :http-request="
                    (params) => {
                      return handlepoll2(params);
                    }
                  "
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove2"
                  :before-remove="beforeRemove"
                  :class="{ disabled: uploadDisabled2 }"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
              <!-- <div v-else class="recordadd-upload">
                <el-image v-if="form.accessory_list[2].accessory_url" style="width: 160px;height: 160px"
                  :src="form.accessory_list[2].accessory_url"
                  :preview-src-list="[form.accessory_list[2].accessory_url]" alt=""></el-image>
                <img v-else src="../../../assets/image/moren.png" style="width: 160px;height: 160px" />
              </div> -->
            </el-form-item>
          </el-col>

          <el-col :span="11" class="mt20 clear">
            <el-form-item
              label="登录账号(手机号)："
              size="medium"
              class="w"
              label-width="28%"
              prop="user_loginname"
              :rules="[
                { required: true, message: '请输入登录账号', trigger: 'blur' },
                {
                  pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
                  message: '不允许输入空格等特殊符号',
                },
                { maxlength: 50, message: '最多输入50个字符' },
              ]"
            >
              <el-input
                placeholder="登录账号(手机号)"
                maxlength="30"
                clearable
                v-model="form.user_loginname"
                class="input-with-select"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="mt20">
            <el-form-item
              label="姓名："
              size="medium"
              class="w"
              label-width="28%"
              prop="user_name"
              :rules="[
                { required: true, message: '请输入姓名', trigger: 'blur' },
                {
                  pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
                  message: '不允许输入空格等特殊符号',
                },
                { maxlength: 50, message: '最多输入50个字符' },
              ]"
            >
              <el-input
                placeholder="姓名"
                maxlength="30"
                clearable
                v-model="form.user_name"
                class="input-with-select"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="11" class="">
            <el-form-item
              label="性别："
              size="medium"
              class="w"
              label-width="28%"
              prop="user_sex"
              :rules="[
                {
                  required: true,
                  message: '请输入性别',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-select v-model="form.user_sex" clearable placeholder="性别">
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item
              class="w"
              label-width="28%"
              label="出生日期："
              size="medium"
            >
              <el-date-picker
                type="date"
                clearable
                placeholder="出生日期"
                :picker-options="pickerOptionsEnd"
                v-model="form.user_born"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item
              class="w"
              label-width="28%"
              label="身份证号："
              size="medium"
              prop="user_idcard"
              :rules="[
                {
                  required: true,
                  message: '请输入身份证号',
                  trigger: ['blur', 'change'],
                },
                {
                  min: 15,
                  max: 18,
                  message: '请如实填写15或者18位号码，以供核对',
                  trigger: ['blur', 'change'],
                },
                {
                  pattern:
                    /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
                  message: '请输入正确的身份证号码',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                placeholder="请输入18位身份证号码"
                clearable
                v-model="form.user_idcard"
                class="input-with-select"
                @blur="selectCard(form.user_idcard)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item
              class="w"
              label-width="28%"
              label="身份证有效期："
              size="medium"
            >
              <el-input
                placeholder="身份证有效期"
                maxlength="30"
                clearable
                v-model="form.user_idcard_expirationtime"
                class="input-with-select"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class=" ">
            <el-form-item
              label="发证机关："
              size="medium"
              class="w"
              label-width="28%"
              prop="user_idcard_government"
              :rules="[
                {
                  required: true,
                  message: '请输入发证机关',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                placeholder="发证机关"
                maxlength="30"
                clearable
                v-model="form.user_idcard_government"
                class="input-with-select"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" class="tl">
            <el-form-item
              class="w"
              label-width="28%"
              label="健康证有效期："
              size="medium"
            >
              <el-date-picker
                type="date"
                clearable
                placeholder="健康证有效期"
                :picker-options="pickerOptions"
                v-model="form.user_health_datedue"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="22" class="tl">
            <el-row class="box f14">
              <el-form-item
                class="w"
                label-width="14%"
                label="所在地址："
                size="medium"
              >
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select
                      clearable
                      v-model="provinceTemp"
                      placeholder="请选择省级"
                      @change="handleChange($event, 2)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in provinceOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select
                      clearable
                      v-model="cityTemp"
                      placeholder="请选择市级"
                      @change="handleChange($event, 3)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in cityOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select
                      clearable
                      v-model="areaTemp"
                      placeholder="请选择区/县"
                      @change="handleChange($event, 4)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in areaOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select
                      clearable
                      v-model="townTemp"
                      placeholder="请选择街道/镇"
                      @change="handleChange($event, 5)"
                      value-key="region_name"
                    >
                      <el-option
                        v-for="item in townOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select
                      clearable
                      v-model="villTemp"
                      placeholder="请选择居委会/村"
                      value-key="region_name"
                      @change="handleChange($event, 6)"
                    >
                      <el-option
                        v-for="item in villOptions"
                        :key="item.region_code"
                        :label="item.region_name"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
          </el-col>
          <el-col :span="11" class=" ">
            <el-form-item
              label="详细地址："
              size="medium"
              class="w"
              label-width="28%"
            >
              <el-input
                placeholder="如道路、门牌号、小区、楼栋号、单元室等（请勿重复录入省市区县信息）"
                maxlength="30"
                clearable
                v-model="form.user_address"
                class="input-with-select"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="22" class="tl">
            <el-button
              class="ml_5"
              size="medium"
              type="primary"
              @click="submitForm('form')"
              >提交</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local, aa, objKeySort, session } from "../../../util/util";
import { imageUrl } from "../../../config/index";
import { tengxuncloud } from "@/util/aliOss";
export default {
  data() {
    return {
      morenUrl: require("../../../assets/image/moren.png"),
      imageUrl: imageUrl,
      user_type: "",
      loading: false,
      options: [
        { label: "男", value: "1" },
        { label: "女", value: "2" },
      ],
      options1: [],
      options2: [],
      options3: [
        { label: "是", value: "1" },
        { label: "否", value: "2" },
      ],
      show: true,
      isdisabled: true,
      form: {
        user_loginname: "",
        user_id: "",
        role_id: "",
        user_type: "",
        user_name: "",
        user_sex: "",
        user_born: "",
        user_idcard: "",
        user_idcard_government: "",
        user_idcard_expirationtime: "",
        cold_chain_personnel: "",
        user_province: "",
        user_city: "",
        user_area: "",
        user_town: "",
        user_vill: "",
        user_address: "",
        department_id: "",
        accessory_list: [
          { accessory_type: "身份证头像面", accessory_url: "" },
          { accessory_type: "身份证国徽面", accessory_url: "" },
          { accessory_type: "健康证", accessory_url: "" },
        ],
      },
      fileList: [],
      fileList1: [],
      fileList2: [],
      region_high_code: "",
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      provinceFlag: false,
      cityFlag: false,
      areaFlag: false,
      townFlag: false,
      villFlag: false,
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],
      uploadDisabled: false,
      uploadDisabled1: false,
      uploadDisabled2: false,
      uploadDisabled11: false,
      dialogImageUrl: "",
      dialogVisible: false,
      pickerOptionsEnd: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      user: {},
      userData: {},
    };
  },
  async mounted() {
    var that = this;
    that.user_type = local.get("user").user_type;
    this.form.user_id = session.get("params").id
      ? String(session.get("params").id)
      : "";
    this.form.department_id = session.get("params").department_id
      ? String(session.get("params").department_id)
      : "";
    // this.company_id =local.get('company_id')?String(local.get('company_id')):'';
    this.userData.user_type = local.get("user").user_type;
    await this.getData(this.form.user_id);
  },

  methods: {
    getData(params) {
      var that = this;
      if (this.form.user_id) {
        axios.get("/pc/user/detail", { user_id: params }).then((v) => {
          that.user = v.data.user;
          that.form.role_id = that.user.role_id
            ? String(that.user.role_id)
            : "";
          that.form.user_name = that.user.user_name;
          that.form.user_loginname = that.user.user_mobilephone;
          that.form.user_born = that.user.user_born;
          that.form.user_address = that.user.user_address;
          that.form.user_idcard = that.user.user_idcard;
          that.form.user_idcard_government = that.user.user_idcard_government;
          that.form.user_idcard_expirationtime =
            that.user.user_idcard_expirationtime;
          that.form.user_health_datedue = that.user.user_health_datedue;
          that.form.user_sex = String(that.user.user_sex);
          that.form.accessory_list = [
            {
              accessory_type: "身份证头像面",
              accessory_url: that.user.accessory_list[0]
                ? that.user.accessory_list[0].accessory_url
                : this.morenUrl,
            },
            {
              accessory_type: "身份证国徽面",
              accessory_url: that.user.accessory_list[1]
                ? that.user.accessory_list[1].accessory_url
                : this.morenUrl,
            },
            {
              accessory_type: "健康证",
              accessory_url: that.user.accessory_list[2]
                ? that.user.accessory_list[2].accessory_url
                : this.morenUrl,
            },
          ];
          that.fileList = [
            {
              name: "身份证头像面",
              url: that.user.accessory_list[0]
                ? that.user.accessory_list[0].accessory_url
                : this.morenUrl,
            },
          ];
          that.uploadDisabled = true;
          that.fileList1 = [
            {
              name: "身份证国徽面",
              url: that.user.accessory_list[1]
                ? that.user.accessory_list[1].accessory_url
                : this.morenUrl,
            },
          ];
          that.uploadDisabled1 = true;
          that.fileList2 = [
            {
              name: "健康证",
              url: that.user.accessory_list[2]
                ? that.user.accessory_list[2].accessory_url
                : this.morenUrl,
            },
          ];
          that.uploadDisabled2 = true;
          if (that.user_type == 3) {
            this.handChange(this.form.user_name);
            //   axios.get('/pc/company-employ/all',{company_id:this.company_id}).then((v) => {
            //  this.options2=v.data

            //     })
          }
          that.handleUserLevel(that.userData, that.user);
        });
      } else {
        that.handleUserLevel(that.userData, "");
      }
    },
    handChange(val) {
      if (val != null && val != "") {
        var obj = {};
        obj = this.options2.find(function (item) {
          return item.company_employ_name == val;
        });
        this.form.cold_chain_personnel = obj.cold_chain_personnel;
      } else {
        this.form.cold_chain_personnel = "";
      }
    },
    submitForm(form) {
      this.form.user_type = "2";
      this.form.user_province = this.provinceTemp.region_name;
      this.form.user_city = this.cityTemp.region_name;
      this.form.user_area = this.areaTemp.region_name;
      this.form.user_town = this.townTemp.region_name;
      this.form.user_vill = this.villTemp.region_name;
      this.$refs[form].validate((valid) => {
        if (valid) {
          axios.post("/pc/user/saveSupervisionUser", this.form).then((v) => {
            this.$router.back();
          });
        } else {
          this.$message.error("请检查表单必填项");
          return false;
        }
      });
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isLt10M;
    },
    selectCard(val) {
      this.getIdCard(val);
    },
    getIdCard(params) {
      axios.get("/pc/region/idCard", { id_card: params }).then((v) => {
        this.form.user_province = v.data.region_province;
        this.form.user_city = v.data.region_city;
        this.form.user_area = v.data.region_area;
        this.handleUserLevel(this.userData, this.form);
      });
    },
    handlepoll(response, file, fileList) {
      this.uploadDisabled = true;
      if (this.imageUrl == "1234567") {
        this.$set(this.form.accessory_list, 0, {
          accessory_type: "身份证头像面",
          accessory_url: response.data.visit_url,
        });
        this.getCard(response.data.visit_url);
      } else {
        tengxuncloud(response).then((res) => {
          this.$set(this.form.accessory_list, 0, {
            accessory_type: "身份证头像面",
            accessory_url: res.url,
          });
          this.getCard(res.url);
        });
      }
    },
    getCard(params) {
      axios
        .get("/pc/baidu/orc/discern", {
          file_path: params,
          discern_type: "1",
          id_card_side: "front",
        })
        .then((v) => {
          if (JSON.stringify(v.data.discern_result) != "{}") {
            this.form.user_idcard = v.data.discern_result.user_idcard;
            this.form.user_sex =
              v.data.discern_result.user_sex == "男" ? "1" : "2";
            this.form.user_born = v.data.discern_result.user_born;
            this.form.user_name = v.data.discern_result.user_name;
            this.form.user_address = v.data.discern_result.user_address;
            if (this.form.user_idcard) {
              this.getIdCard(this.form.user_idcard);
            }
            //  this.handleUserLevel(this.userData,this.user);
          } else {
            this.$message({
              message: "请上传对应的身份证附件",
              type: "warning",
              duration: 2 * 1000,
            });
          }
        });
    },
    handlepoll1(response, file, fileList) {
      this.uploadDisabled1 = true;
      if (this.imageUrl == "1234567") {
        this.$set(this.form.accessory_list, 1, {
          accessory_type: "身份证国徽面",
          accessory_url: response.data.visit_url,
        });
        this.getCard(response.data.visit_url);
      } else {
        tengxuncloud(response).then((res) => {
          this.$set(this.form.accessory_list, 1, {
            accessory_type: "身份证国徽面",
            accessory_url: res.url,
          });
          this.getCard1(res.url);
        });
      }
    },
    getCard1(params) {
      axios
        .get("/pc/baidu/orc/discern", {
          file_path: params,
          discern_type: "1",
          id_card_side: "back",
        })
        .then((v) => {
          if (JSON.stringify(v.data.discern_result) != "{}") {
            this.form.user_idcard_government =
              v.data.discern_result.user_idcard_government;
            this.form.user_idcard_expirationtime =
              v.data.discern_result.user_idcard_expirationtime;
          } else {
            this.$message({
              message: "请上传对应的身份证附件",
              type: "warning",
              duration: 2 * 1000,
            });
          }
        });
    },
    handlepoll2(response, file, fileList) {
      this.uploadDisabled2 = true;
      if (this.imageUrl == "1234567") {
        this.$set(this.form.accessory_list, 2, {
          accessory_type: "健康证",
          accessory_url: response.data.visit_url,
        });
      } else {
        tengxuncloud(response).then((res) => {
          this.$set(this.form.accessory_list, 2, {
            accessory_type: "健康证",
            accessory_url: res.url,
          });
        });
      }
    },

    handleRemove(file, fileList) {
      this.uploadDisabled = false;
      this.$set(this.form.accessory_list, 0, {
        accessory_type: "身份证头像面",
        accessory_url: "",
      });
    },
    handleRemove1(file, fileList) {
      this.uploadDisabled1 = false;
      this.$set(this.form.accessory_list, 1, {
        accessory_type: "身份证国徽面",
        accessory_url: "",
      });
    },
    handleRemove2(file, fileList) {
      this.uploadDisabled2 = false;
      this.$set(this.form.accessory_list, 2, {
        accessory_type: "健康证",
        accessory_url: "",
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeRemove(file, fileList) {
      if (file && file.status === "success") {
        return this.$confirm(`确定移除 ${file.name}吗？`);
      }
    },
    async handleUserLevel(userData, user) {
      if (parseInt(userData.user_type) > 0 || user.user_province) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
          (item) =>
            item.region_name ==
            (user.user_province ? user.user_province : userData.user_province)
          //    item =>item.region_name ==(userData.user_province ?userData.user_province :company.province  )
        );
        this.provinceTemp = province;
        if (parseInt(userData.user_type) >= 1 || user.user_city) {
          this.cityOptions = await this.fetchRegionData(
            2,
            province.region_code
          );
          let city = this.cityOptions.find(
            (item) =>
              item.region_name ==
              (userData.user_city ? userData.user_city : user.user_city)
          );
          if (parseInt(userData.user_type) >= 1) this.provinceFlag = true;
          if (parseInt(userData.user_type) >= 2 || user.user_area) {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
              (item) =>
                item.region_name ==
                (userData.user_area ? userData.user_area : user.user_area)
            );
            this.cityTemp = city;
            if (parseInt(userData.user_type) >= 2) this.cityFlag = true;
            if (parseInt(userData.user_type) >= 3 || user.user_town) {
              this.townOptions = await this.fetchRegionData(
                4,
                area.region_code
              );
              let town = this.townOptions.find(
                (item) =>
                  item.region_name ==
                  (userData.user_town ? userData.user_town : user.user_town)
              );
              this.areaTemp = area;
              if (parseInt(userData.user_type) >= 3) this.areaFlag = true;
              if (parseInt(userData.user_type) >= 4 || user.user_vill) {
                this.villOptions = await this.fetchRegionData(
                  5,
                  town.region_code
                );
                let vill = this.villOptions.find(
                  (item) =>
                    item.region_name ==
                    (userData.user_vill ? userData.user_vill : user.user_vill)
                );
                this.townTemp = town;
                if (parseInt(userData.user_type) >= 4) this.townFlag = true;
                if (parseInt(userData.user_type) >= 5 || user.user_vill) {
                  this.villTemp = vill;
                  if (parseInt(userData.user_type) >= 5) this.villFlag = true;
                }
              }
            }
          }
        }
      }
    },
    fetchRegionData(type, region_high_code) {
      let region_type = type.toString();
      return new Promise((resolve, reject) => {
        axios
          .get("/pc/region/all", { region_type, region_high_code })
          .then((response) => {
            resolve(response.data.region_list);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async handleChange(item, type) {
      let regionData;
      if (item.region_code == undefined) {
        regionData = "";
      } else {
        regionData = await this.fetchRegionData(type, item.region_code);
      }
      switch (type) {
        case 2:
          this.cityOptions = regionData;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.cityTemp = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 3:
          this.areaOptions = regionData;
          this.townOptions = [];
          this.villOptions = [];
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 4:
          this.townOptions = regionData;
          this.villOptions = [];
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.form.town = item.region_name;
          this.villTemp = "";
          break;
        case 6:
          this.form.vill = item.region_name;
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .recordadd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/ .el-checkbox-button__inner {
    border-left: 1px solid #dcdfe6;
    border-radius: 4px !important;
  }
  /deep/ .el-table.no-header .el-table__header {
    display: none;
  }
  .people-message .el-form-item {
    margin-bottom: 0px;
  }
  .recordadd-upload {
    width: 160px;
    height: 160px;
    float: left;
    margin-right: 10px;
    .disabled {
      height: 100%;
    }
    /deep/ .el-upload {
      width: 160px;
      height: 160px;
      line-height: 160px;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 160px;
      height: 160px;
    }
  }
  .health_url {
    /deep/ .el-upload--picture-card {
      width: 70px;
      height: 40px;
      background-color: transparent;
      border: none;
      .el-button--medium {
        position: absolute;
        left: 10px;
        margin-top: 4px;
      }
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 70px;
      height: 40px;
    }
  }
  /deep/ .el-col-7 .el-form-item__content {
    width: initial;
  }
  table .cell .is-error {
    padding-bottom: 20px;
  }
  .el-table th.is-leaf,
  .el-table td {
    border-bottom: none;
  }
  /deep/ .disabled .el-upload--picture-card {
    display: none !important;
  }
  /deep/ .el-table {
    .el-table__body,
    .el-table__header {
      width: 100% !important;
    }
  }
  .el-checkbox-button {
    margin-bottom: 10px;
  }
}
</style>
